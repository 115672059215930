import styled from "styled-components";

export const RecipeWrapper = styled("div")`
    background: linear-gradient(180deg,#ede5e9 450px,#fff 0);
    color: ${({theme}: any) => theme['dark']};
    padding: 1.5rem 15rem 5rem;

    p {
        color: ${({theme}: any) => theme['dark']};
        line-height: 1.75;
        margin-bottom: 0;
    }
    
    @media only screen and (max-width: 1366px) {
        padding: 1.5rem 10rem 5rem;
    }
    @media only screen and (max-width: 1024px) {
        padding: 1.5rem 5rem 3rem;
    }
    @media only screen and (max-width: 768px) {
        background: linear-gradient(180deg,#e5ddd9 350px,#fff 0);
        padding: 1.5rem 3rem 3rem;

        p {
            line-height: 1.25;
            margin-bottom: 0;
        }
    }
    @media only screen and (max-width: 414px) {
        padding: 1.5rem 2rem 2rem;
    }

`;

export const RecipeTitle = styled("div")`
    h1 { 
        color: ${({theme}: any) => theme['dark']};
        margin-bottom: 0;
    }
`;

export const RecipeTagWrapper = styled("div")`
    margin: 0.5rem 0;
    display: flex;
    justify-content: flex-end;
`;
export const RecipeTag = styled("div")`
    margin: 0 0.5rem;
    span{
        font-size: 20px;
        @media only screen and (max-width: 1366px) {
            font-size: 20px;
        }
    
        @media only screen and (max-width: 414px) {
            font-size: 15px;
        }
    }
    img{
        margin-right: 0.5rem;
    }
`;

export const CoverImage = styled("div")<any>`
    background-image: URL(${(p:any) => ('/assets/img/'+p.background)});
    background-position: ${(p:any) => p.backgroundPos};
    background-position-x: ${(p:any) => 'calc(100% * ' + p.backgroundPosX + ' )'};
    background-position-y: ${(p:any) => 'calc(100% * ' + p.backgroundPosY + ' )'};
    background-size: ${(p:any) => p.backgroundSize ? p.backgroundSize : 'cover'};
    background-repeat: no-repeat;
    height: 45rem;

    @media only screen and (max-width: 2048px) {
        height: 35rem;
    }
    @media only screen and (max-width: 1366px) {
        height: 24rem;
    }
    @media only screen and (max-width: 768px) {
        height: 17rem;
    }
    @media only screen and (max-width: 450px) {
        height: 12rem;
    }
`;

export const RecipeContent = styled("div")<any>`
    display: flex;
    flex-direction: row;
    margin-top: 2rem;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }

    ul {
        list-style-type: none;
        padding-inline: 0;
    }
`;

export const SubTitle = styled("div")<any>`
    text-align: center;
    margin-bottom: 2rem;

    span{
        font-size: 32px;
        font-weight: 600;
        padding: 0.5rem 2rem;
        border-bottom: 4px solid #000;

        @media only screen and (max-width: 1366px) {
            font-size: 28px;
        }
        
        @media only screen and (max-width: 414px) {
            font-size: 23px;
        }
    }
`;

export const Ingredients = styled("div")<any>`
    text-align: center;
    width: 35%;
    padding: 2rem;
    
    .group-title{
        margin-top: 15px;
        margin-bottom: 5px;
        font-weight: bold;
    }

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
`;
    
export const Instructions = styled("div")<any>`
    width: 64%;
    padding: 2rem;

    .group-title{
        margin-top: 15px;
        margin-bottom: 5px;
        font-weight: bold;
    }
    
    li::marker{
        font-size: 20px;
        font-weight: 600;
    }
    
    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
`;

export const Notes = styled("div")<any>`
    font-style: italic;
    ul {
        list-style-type: none;
        padding-inline: 0;
    }

    div {
        text-align: left;
        margin-bottom: 0;

        span{
            padding-right: 15rem;
            padding-left: 1rem;
            border-top: 4px solid #000;
            border-bottom: none;
        }
    }

`;
