import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    iframe {
        display: none;
    }
    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }

  
    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }
    
    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: ${({theme}: any) => theme['white']};
        overflow-x: hidden;
    }

    a:hover {
        color:${({theme}: any) => theme['white']};
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: ${({theme}: any) => theme['white']};
        color: ${({theme}: any) => theme['dark']};
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        &::placeholder{
            color: ${({theme}: any) => theme['dark']};
        }

        &:focus-within {
            background-color: ${({theme}: any) => theme['white']};;
            box-shadow: ${({theme}: any) => theme['white']} 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h4,
    h5,
    h6 {
        // font-family: 'Motiva Sans Bold', serif;
        color: ${({theme}: any) => theme['dark']};
        font-size: 45px;
        line-height: 1.25;

        @media only screen and (max-width: 1366px) {
          font-size: 35px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 30px;
        }
    }

    h3{
        color: ${({theme}: any) => theme['dark']};
        font-size: 35px;
        font-weigh: 600px !important;

        @media only screen and (max-width: 1366px) {
          font-size: 30px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 25px;
        }
    }

    p, span {
        color: ${({theme}: any) => theme['dark']};
        font-size: 20px;        
        line-height: 1.41;
        @media only screen and (max-width: 1366px) {
            font-size: 17px;
        }
    
        @media only screen and (max-width: 414px) {
            font-size: 15px;
        }
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: ${({theme}: any) => theme['white']};

        :hover {
            color: ${({theme}: any) => theme['white']};
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;
