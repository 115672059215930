import { useState, useEffect } from "react";
import pages from '../../content/pages';

import defaultTransaltions from './default-translations.en.json';

export const useEnTranslations = () => {
  let [translations, setTranslations] = useState(defaultTransaltions);

  useEffect(() => {
    pages
      .forEach(page => import(`./${page.tlFileName}.en.json`)
        .then(pageContent => 
          setTranslations((old:any) => ({...old, pages: {...old.pages, [page.key]: pageContent}}))))
  }, []);

  return {
    ...translations
  }
}; 