import styled from "styled-components";

export const CarouselSection = styled("section")`

video {
  max-height: 700px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image{
  max-height: calc(100vh - 150px);
}
`;

