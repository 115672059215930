import { type } from 'os';
import React, { useEffect, useState }  from 'react';
import { lazy } from "react";
import {Trans, withTranslation} from "react-i18next";
import { SvgIcon } from '../../common/SvgIcon';
import ZSCarousel from '../../components/ZSCarousel';
import { 
  RecipeWrapper,
  RecipeTitle,
  CoverImage,
  RecipeTagWrapper,
  RecipeTag,
  RecipeContent,
  Ingredients,
  Instructions,
  SubTitle,
  Notes
 } from './styles';

const Contact = lazy(() => import("../../components/ContactForm"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const Recipe = ({t, pageData}: any) => {

  const [tags, setTags] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [beforeInstructions, setBeforeInstructions] = useState([]);
  const [notes, setNotes] = useState([]);
  
  const tl = (prop: string) => t(`pages.${pageData.key}.${prop}`);
  const trs = (prop: string) => <Trans i18nKey={`pages.${pageData.key}.${prop}`}  />;

  useEffect(() => {
    if (typeof tl('tags') === 'object'){
      setTags(() => tl('tags').map((t: any) => (
        <RecipeTag key={t.description}>
          <SvgIcon src={t.icon} height='25px'/>
          <span>{t.description}</span>
        </RecipeTag>
      )))
    }

    if (typeof tl('ingredients') === 'object'){
      setIngredients(() => tl('ingredients').list.map((i: any) => (
        <li key={i.description}>
          <p className={i.subtitle ? 'group-title' : ''}>{i.description}</p>
        </li>
      )))
    }

    if (typeof tl('instructions') === 'object'){
      setInstructions(() => tl('instructions').list.map((i: any) => (
        i.subtitle 
        ? (<p className={'group-title'} dangerouslySetInnerHTML={{ __html: i.description }}></p>) 
        : (<li key={i.description}>
          <p dangerouslySetInnerHTML={{ __html: i.description }}></p>
        </li>)
      )))
    }

    if (typeof tl('beforeInstructions') === 'object'){
      setBeforeInstructions(() => tl('beforeInstructions').list.map((i: any) => (
        <li key={i.description}>
          <p  dangerouslySetInnerHTML={{ __html: i.description }}></p>
        </li>
      )))
    }

    if (typeof tl('notes') === 'object'){
      setNotes(() => tl('notes').list.map((n: any) => (
        <li key={n.description}>
          <p>{n.description}</p>
        </li>
      )))
    }
  }, [t]);
   
  return (
    <Container>
      <ScrollToTop />
      <RecipeWrapper>
        <RecipeTitle>
          <h1>{tl('title')}</h1>
          <p>{tl('subtitle')}</p>
        </RecipeTitle>
        <RecipeTagWrapper>
          {tags}
        </RecipeTagWrapper>
        <CoverImage background={pageData.coverImageName} backgroundPos={pageData.coverImageNamePos} backgroundPosX={pageData.coverImagePosX} backgroundPosY={pageData.coverImagePosY} backgroundSize={pageData.backgroundSize}/>
        <RecipeContent>
          <Ingredients>
            <SubTitle><span>{tl('ingredients.title')}</span></SubTitle>
            <ul>
              {ingredients}
            </ul>
          </Ingredients>
          <Instructions>
            {!!beforeInstructions.length && (<><SubTitle><span>{tl('beforeInstructions.title')}</span></SubTitle>
            <ol>
              {beforeInstructions}
            </ol></>)}
            <SubTitle><span>{tl('instructions.title')}</span></SubTitle>
            <ol>
              {instructions}
            </ol>
          </Instructions>
        </RecipeContent>
        {(pageData.carouselImagePaths || pageData.videoUrl) && (<ZSCarousel imagePaths={pageData.carouselImagePaths} videoConfig={pageData.videoConfig}></ZSCarousel>)}
        {!!notes.length && (<Notes>
          <SubTitle><span>{tl('notes.title')}</span></SubTitle>
          <ul>
            {notes}
          </ul>
        </Notes>)}
      </RecipeWrapper>
      <Contact
        id="contact"
      />
    </Container>
  );
};

export default withTranslation()(Recipe);
