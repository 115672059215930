import React  from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";

import Router from "./router";
import {useI18n} from "./translation";

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={useI18n()}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById("root"));
