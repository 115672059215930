import React  from 'react';

import { Row, Col } from "antd";
import { withTranslation, Trans } from "react-i18next";
import { ContentBlockProps } from "../types";
import {
  BannerBlockContainer,
  BannerShadySide,
  BannerLightSide
} from "./styles";

const BannerBlock = ({
  title,
  content,
  icon,
  id,
  background,
  type,
}: ContentBlockProps) => {

  return (
    <BannerBlockContainer background={background} type={type}>
      {/* <Row  >
          <BannerLightSide>
          </BannerLightSide>
          <BannerShadySide>
            <Row>
              <Col style={{margin: "auto"}}>
                <h1><Trans i18nKey={title} /></h1>
              </Col>
              <Col style={{margin: "auto"}}>
                <p><Trans i18nKey={content} /></p>
              </Col>
            </Row>
          </BannerShadySide>
      </Row> */}
    </BannerBlockContainer>
  );
};

export default withTranslation()(BannerBlock);
