const BrownieCuAfineFaraGluten = {
    linkPath: "brownie-cu-afine-fara-gluten",
    tlFileName: "brownie-cu-afine-fara-gluten",
    coverImageName: "brownie-cu-afine-fara-gluten.jpg",
    coverImageNamePos: "top",
    key: "bcafg"
};

const TortDeClatiteFaraGluten = {
    linkPath: "tort-de-clatite-fara-gluten",
    tlFileName: "tort-de-clatite-fara-gluten",
    coverImageName: "tort-de-clatite-fara-gluten/tort-de-clatite-fara-gluten.jpg",
    coverImageNamePos: "center",
    carouselImagePaths: [
        "tort-de-clatite-fara-gluten/1.jpg",
        "tort-de-clatite-fara-gluten/2.jpg",
        "tort-de-clatite-fara-gluten/3.jpg",
        "tort-de-clatite-fara-gluten/4.jpg",
        "tort-de-clatite-fara-gluten/5.jpg",
        "tort-de-clatite-fara-gluten/6.jpg",
        "tort-de-clatite-fara-gluten/7.jpg",
        "tort-de-clatite-fara-gluten/8.jpg",
        "tort-de-clatite-fara-gluten/9.jpg"
    ],
    key: "tdcfg"
}

const GalusteCuPrune = {
    linkPath: "galuste-cu-prune",
    tlFileName: "galuste-cu-prune",
    coverImageName: "galuste-cu-prune/galuste-cu-prune.jpg",
    coverImageNamePos: "center",
    coverImagePosY: "0.4",
    carouselImagePaths: [
        "galuste-cu-prune/1.jpg",
        "galuste-cu-prune/2.jpg",
        "galuste-cu-prune/3.jpg",
        "galuste-cu-prune/4.jpg",
        "galuste-cu-prune/5.jpg",
        "galuste-cu-prune/6.jpg",
        "galuste-cu-prune/7.jpg",
        "galuste-cu-prune/8.jpg",
        "galuste-cu-prune/9.jpg"
    ],
    key: "gcp"
}

const CuburiCuCiocolataSiFulgiDeCocos = {
    linkPath: "cuburi-cu-ciocolata-si-fulgi-de-cocos",
    tlFileName: "cuburi-cu-ciocolata-si-fulgi-de-cocos",
    coverImageName: "cuburi-cu-ciocolata-si-fulgi-de-cocos/cuburi-cu-ciocolata-si-fulgi-de-cocos.jpg",
    coverImageNamePos: "center",
    carouselImagePaths: [
        "cuburi-cu-ciocolata-si-fulgi-de-cocos/1.jpg",
        "cuburi-cu-ciocolata-si-fulgi-de-cocos/2.jpg",
        "cuburi-cu-ciocolata-si-fulgi-de-cocos/3.jpg",
    ],
    key: "cccsfdc"
}

const BatoaneProteiceCuCereale = {
    linkPath: "batoane-proteice-cu-cereale",
    tlFileName: "batoane-proteice-cu-cereale",
    coverImageName: "batoane-proteice-cu-cereale/batoane-proteice-cu-cereale.jpg",
    coverImageNamePos: "center",
    carouselImagePaths: [
        "batoane-proteice-cu-cereale/1.jpg",
        "batoane-proteice-cu-cereale/2.jpg",
        "batoane-proteice-cu-cereale/3.jpg",
        "batoane-proteice-cu-cereale/4.jpg",
    ],
    key: "bpcc"
}

const BrownieCuDovleac = {
    linkPath: "brownie-cu-dovleac",
    tlFileName: "brownie-cu-dovleac",
    coverImageName: "brownie-cu-dovleac/brownie-cu-dovleac.jpg",
    coverImageNamePos: "center",
    coverImagePosY: "0.1",
    carouselImagePaths: [
        "brownie-cu-dovleac/1.jpg",
        "brownie-cu-dovleac/2.jpg",
        "brownie-cu-dovleac/3.jpg",
        "brownie-cu-dovleac/4.jpg",
        "brownie-cu-dovleac/5.jpg",
        "brownie-cu-dovleac/6.jpg",
        "brownie-cu-dovleac/7.jpg",
        "brownie-cu-dovleac/8.jpg",
        "brownie-cu-dovleac/9.jpg"
    ],
    key: "bcd"
}
const MuffinCuMere = {
    linkPath: "muffin-cu-mere",
    tlFileName: "muffin-cu-mere",
    coverImageName: "muffin-cu-mere/muffin-cu-mere.jpg",
    coverImageNamePos: "center",
    carouselImagePaths: [
        "muffin-cu-mere/1.jpg",
        "muffin-cu-mere/2.jpg",
        "muffin-cu-mere/3.jpg",
    ],
    key: "mcm"
}

const TurtaDulce = {
    linkPath: "turta-dulce",
    tlFileName: "turta-dulce",
    coverImageName: "turta-dulce/turta-dulce.jpg",
    coverImageNamePos: "center",
    carouselImagePaths: [
        "turta-dulce/1.jpg",
        "turta-dulce/2.jpg",
        "turta-dulce/3.jpg",
        "turta-dulce/4.jpg",
    ],
    key: "td"
}

const RuladaCuUntDeArahide = {
    linkPath: "rulada-cu-unt-de-arahide",
    tlFileName: "rulada-cu-unt-de-arahide",
    coverImageName: "rulada-cu-unt-de-arahide/rulada-cu-unt-de-arahide.jpg",
    coverImageNamePos: "bottom",
    coverImagePosY: "0.8",
    carouselImagePaths: [
        "rulada-cu-unt-de-arahide/1.jpg",
        "rulada-cu-unt-de-arahide/2.jpg",
        "rulada-cu-unt-de-arahide/3.jpg",
    ],
    key: "rcuda"
}

const BiscuitiCuOvazSiPortocale = {
    linkPath: "biscuiti-cu-ovaz-si-portocale",
    tlFileName: "biscuiti-cu-ovaz-si-portocale",
    coverImageName: "biscuiti-cu-ovaz-si-portocale/biscuiti-cu-ovaz-si-portocale.jpg",
    coverImageNamePos: "center",
    coverImagePosY: "0.4",
    carouselImagePaths: [
        "biscuiti-cu-ovaz-si-portocale/1.jpg",
        "biscuiti-cu-ovaz-si-portocale/2.jpg",
        "biscuiti-cu-ovaz-si-portocale/3.jpg",
    ],
    key: "bcosp"
}

const PapanasCuBranza = {
    linkPath: "papanas-cu-branza",
    tlFileName: "papanas-cu-branza",
    coverImageName: "papanas-cu-branza/papanas-cu-branza.jpg",
    coverImageNamePos: "center",
    coverImagePosY: "0.6",
    carouselImagePaths: [
        "papanas-cu-branza/1.jpg",
        "papanas-cu-branza/2.jpg",
        "papanas-cu-branza/3.jpg",
        "papanas-cu-branza/4.jpg",
        "papanas-cu-branza/5.jpg",
    ],
    key: "pcb"
}

const GretaGarbo = {
    linkPath: "greta-garbo",
    tlFileName: "greta-garbo",
    coverImageName: "greta-garbo/greta-garbo.jpg",
    coverImageNamePos: "center",
    // coverImagePosX: ""
    coverImagePosY: "0.8",
    carouselImagePaths: [
        "greta-garbo/1.jpg",
        "greta-garbo/2.jpg",
        "greta-garbo/3.jpg",
        "greta-garbo/4.jpg",
        "greta-garbo/5.jpg",
        "greta-garbo/6.jpg",
        "greta-garbo/7.jpg",
        "greta-garbo/8.jpg",
    ],
    key: "gg"
}

const BrownieCuInghetata = {
    linkPath: "brownie-cu-inghetata",
    tlFileName: "brownie-cu-inghetata",
    coverImageName: "brownie-cu-inghetata/brownie-cu-inghetata.jpg",
    coverImageNamePos: "center",
    // coverImagePosX: ""
    coverImagePosY: "0.5",
    carouselImagePaths: [
        "brownie-cu-inghetata/1.jpg",
        "brownie-cu-inghetata/2.jpg",
        // "brownie-cu-inghetata/4.jpg",
        // "brownie-cu-inghetata/5.jpg",
        // "brownie-cu-inghetata/6.jpg",
        // "brownie-cu-inghetata/7.jpg",
        // "brownie-cu-inghetata/8.jpg",
        // "brownie-cu-inghetata/9.jpg",
        // "brownie-cu-inghetata/10.jpg",
        // "brownie-cu-inghetata/11.jpg",
        // "brownie-cu-inghetata/12.jpg",
        // "brownie-cu-inghetata/13.jpg",
        "brownie-cu-inghetata/gif.gif",
    ],
    key: "bci"
}

const ClatiteAmericane = {
    linkPath: "clatite-americane",
    tlFileName: "clatite-americane",
    coverImageName: "clatite-americane/clatite-americane.jpg",
    coverImageNamePos: "center",
    coverImagePosX: "0.5",
    coverImagePosY: "0.5",
    carouselImagePaths: [
        "clatite-americane/1.jpg",
        "clatite-americane/2.jpg",
        "clatite-americane/3.jpg",
    ],
    key: "ca"
}

const TartaCuPiersici = {
    linkPath: "tarta-cu-piersici",
    tlFileName: "tarta-cu-piersici",
    coverImageName: "tarta-cu-piersici/tarta-cu-piersici.jpg",
    coverImageNamePos: "center",
    coverImagePosX: "0.5",
    coverImagePosY: "0.5",
    carouselImagePaths: [
        "tarta-cu-piersici/1.jpg",
        "tarta-cu-piersici/2.jpg",
        "tarta-cu-piersici/3.jpg",
        "tarta-cu-piersici/4.jpg",
        "tarta-cu-piersici/5.jpg",
        "tarta-cu-piersici/6.jpg",
    ],
    key: "tcp"
}

const PandispanCuFructe = {
    linkPath: "pandispan-cu-fructe",
    tlFileName: "pandispan-cu-fructe",
    coverImageName: "pandispan-cu-fructe/pandispan-cu-fructe.jpg",
    coverImageNamePos: "center",
    coverImagePosX: "0.5",
    coverImagePosY: "0.5",
    // backgroundSize: "950px",
    carouselImagePaths: [
        "pandispan-cu-fructe/1.jpg",
        "pandispan-cu-fructe/2.jpg",
        "pandispan-cu-fructe/3.jpg",
        "pandispan-cu-fructe/4.jpg",
        "pandispan-cu-fructe/5.jpg",
        "pandispan-cu-fructe/6.jpg",
        "pandispan-cu-fructe/7.jpg",
        "pandispan-cu-fructe/8.jpg",
    ],
    key: "pcf"
}

const MalaiCuLapte = {
    linkPath: "malai-cu-lapte",
    tlFileName: "malai-cu-lapte",
    coverImageName: "malai-cu-lapte/malai-cu-lapte.jpg",
    coverImageNamePos: "center",
    coverImagePosX: "0.5",
    coverImagePosY: "0.4",
    // backgroundSize: "950px",
    carouselImagePaths: [
        "malai-cu-lapte/1.jpg",
        "malai-cu-lapte/2.jpg",
        "malai-cu-lapte/3.jpg",
    ],
    key: "mcl"
}
const RuladaTiramisu = {
    linkPath: "rulada-tiramisu",
    tlFileName: "rulada-tiramisu",
    coverImageName: "rulada-tiramisu/rulada-tiramisu.jpg",
    coverImageNamePos: "center",
    coverImagePosX: "0.5",
    coverImagePosY: "0.6",
    // backgroundSize: "950px",
    carouselImagePaths: [
        "rulada-tiramisu/1.jpg",
        "rulada-tiramisu/2.jpg",
        "rulada-tiramisu/3.jpg",
        "rulada-tiramisu/4.jpg",
        "rulada-tiramisu/5.jpg",
    ],
    key: "rt"
}

const GalusteCuPruneFainaIntegrala = {
    linkPath: "galuste-cu-prune-faina-integrala",
    tlFileName: "galuste-cu-prune-faina-integrala",
    coverImageName: "galuste-cu-prune-faina-integrala/galuste-cu-prune-faina-integrala.jpg",
    coverImageNamePos: "center",
    coverImagePosY: "0.4",
    videoConfig: {
        url: "assets/img/galuste-cu-prune-faina-integrala/6.mp4",
        thumbnalPath: "assets/img/galuste-cu-prune-faina-integrala/1.jpg",
    },
    carouselImagePaths: [
        "galuste-cu-prune-faina-integrala/1.jpg",
        "galuste-cu-prune-faina-integrala/2.jpg",
        "galuste-cu-prune-faina-integrala/3.jpg",
        "galuste-cu-prune-faina-integrala/4.jpg",
        "galuste-cu-prune-faina-integrala/5.jpg",
    ],
    key: "gcpfi"
}

const PrajituraCuMereSiBezea = {
    linkPath: "prajitura-cu-mere-si-bezea",
    tlFileName: "prajitura-cu-mere-si-bezea",
    coverImageName: "prajitura-cu-mere-si-bezea/prajitura-cu-mere-si-bezea.jpg",
    coverImageNamePos: "center",
    coverImagePosY: "0.4",
    videoConfig: {
        url: "assets/img/prajitura-cu-mere-si-bezea/6.mp4",
        thumbnalPath: "assets/img/prajitura-cu-mere-si-bezea/1.jpg",
    },
    carouselImagePaths: [
        "prajitura-cu-mere-si-bezea/1.jpg",
        "prajitura-cu-mere-si-bezea/2.jpg",
        "prajitura-cu-mere-si-bezea/3.jpg",
        "prajitura-cu-mere-si-bezea/4.jpg",
        "prajitura-cu-mere-si-bezea/5.jpg",
    ],
    key: "pcmsb"
}
const TartaDeCartofiDulci = {
    linkPath: "tarta-de-cartofi-dulci",
    tlFileName: "tarta-de-cartofi-dulci",
    coverImageName: "tarta-de-cartofi-dulci/tarta-de-cartofi-dulci.jpg",
    coverImageNamePos: "center",
    coverImagePosY: "0.65",
    videoConfig: {
        url: "assets/img/tarta-de-cartofi-dulci/1.mp4",
        thumbnalPath: "assets/img/tarta-de-cartofi-dulci/1.jpg",
    },
    carouselImagePaths: [
        "tarta-de-cartofi-dulci/1.jpg",
        "tarta-de-cartofi-dulci/2.jpg",
        "tarta-de-cartofi-dulci/3.jpg",
        "tarta-de-cartofi-dulci/4.jpg",
        "tarta-de-cartofi-dulci/5.jpg",
    ],
    key: "tdcd"
}

const pages = [
    // BrownieCuAfineFaraGluten,
    // TortDeClatiteFaraGluten,
    // CuburiCuCiocolataSiFulgiDeCocos,
    // BatoaneProteiceCuCereale,
    // GalusteCuPrune,
    // BrownieCuDovleac,
    // MuffinCuMere,
    // TurtaDulce,
    // RuladaCuUntDeArahide,
    // BiscuitiCuOvazSiPortocale,
    // PapanasCuBranza,
    // GretaGarbo,
    // BrownieCuInghetata,
    // ClatiteAmericane,
    // TartaCuPiersici,
    // PandispanCuFructe,
    // MalaiCuLapte,
    // RuladaTiramisu,
    // GalusteCuPruneFainaIntegrala,
    // PrajituraCuMereSiBezea,
    TartaDeCartofiDulci
]

export default pages;