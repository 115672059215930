import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${({theme}: any) => theme['white']};;
  color: ${({theme}: any) => theme['accent']};
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  border: 1px solid ${({theme}: any) => theme['dark']};
  // border-radius: 4px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  // box-shadow: 0 16px 30px rgb(23 31 114 / 20%);

  &:hover,
  &:active,
  &:focus {
    color: ${({theme}: any) => theme['white']};
    border: 1px solid ${({theme}: any) => theme['accent']};
    background-color: ${({theme}: any) => theme['accent']};
  }
`;
