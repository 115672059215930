import React  from 'react';

import { withTranslation } from "react-i18next";
import { CarouselSection } from "./styles";
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

interface VideoConfig {
    url: string,
    thumbnalPath: string
}

interface ZSCarouselProps {
    imagePaths: string[],
    videoConfig?: VideoConfig,
}

const ZSCarousel = ({ imagePaths, videoConfig }: ZSCarouselProps) => {
    let images: ReactImageGalleryItem[] = [];
    if (videoConfig){
        images.push(
            {
                original: videoConfig.thumbnalPath,
                thumbnail: videoConfig.thumbnalPath,
                thumbnailHeight:80,
                renderItem: (item: ReactImageGalleryItem) => 
                    (<video height={'100%'} width={'100%'} autoPlay={true} controls={true}><source src={videoConfig.url} type="video/mp4"/></video>)
            }
        );
    }

    images = images.concat(imagePaths.map(p => ({
        original: `assets/img/${p}`,
        thumbnail: `assets/img/${p}`,
        thumbnailHeight:80,
    })));

    return (
        <>
        <CarouselSection>
            <ImageGallery 
                items={images}
                showNav={false} 
                showPlayButton={false} 
                thumbnailPosition={'bottom'}
            />
        </CarouselSection>
        </>
    );
};

export default withTranslation()(ZSCarousel);
