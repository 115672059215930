import React  from 'react';
import { lazy } from "react";
import { withTranslation } from 'react-i18next';
import BannerContentBlock from '../../components/ContentBlock/BannerContentBlock';
import Snowfall from 'react-snowfall';

const Contact = lazy(() => import("../../components/ContactForm"));
const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const Home = ({t}: any) => {
  return (
    <Container>
      <ScrollToTop />
      {/* <Snowfall style={{zIndex: 1, position: "fixed", height: "100vh", width: "100vw"}} 
        snowflakeCount={150}
        speed={[0.1, 0.2]}
        radius={[0.5, 1.5]}
        wind={[-0.5, 0.3]}/> */}
      <BannerContentBlock
        title={'home.banner.title'}
        content={'home.banner.text'}
        button={'home.banner.button'}
        id="intro"
        background="22.png"
      />
      
      {/* <MiddleBlock
        title={'home.intro.title'}
        content={'home.intro.description'}
        id="intro"
        // videoUrl="/video/candy_bar_muted.mp4"
      /> */}
      <Contact
        id="contact"
      />
    </Container>
  );
};

export default withTranslation()(Home);
