import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import { useEnTranslations } from "./locales/en/useEnTranslation";
import { useRoTranslations } from "./locales/ro/useRoTranslation";
import { useHuTranslations } from "./locales/hu/useHuTranslation";

export const useI18n = () => {
    i18n
        .use(XHR)
        .use(LanguageDetector)
        .init({
            detection: {order: ['localStorage', 'path']},
            debug: false,
            fallbackLng: "hu",

            interpolation: {
                escapeValue: false,
            },

            resources: {
                en: {
                    translations: useEnTranslations(),
                },
                hu: {
                    translations: useHuTranslations(),
                },
            },
            ns: ["translations"],
            defaultNS: "translations",
            returnObjects: true,
        });
    return i18n;
};
