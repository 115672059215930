import React  from 'react';

import { lazy, Suspense, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Styles } from "../styles/styles";
import {ThemeProvider} from 'styled-components';
import Home from '../pages/Home';
import pages from '../content/pages';
import Recipe from '../pages/Recipe';

declare const window: any;

const Router = () => {
  const location = useLocation();

  useEffect(() => {
    if (!!window.gtag){
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
  }, []);


    const theme = {
        'main': '#917784',
        // 'dark': '#271B30',
        // 'dark': '#111812',
        // 'dark': '#130e09',
        'dark': '#051118',
        'light': '#DDB996',
        'white': '#f2f2f2',
        // 'white': '#F5F6F4',
        'accent': '#BB3B63'
    };
  return (
    <Suspense fallback={null}>
        <ThemeProvider theme={theme}>
          <Styles />
          <Header />
          <Switch>
            {
              pages.map(page => {
                return (
                  <Route 
                    key={page.linkPath}
                    path={[`/${page.linkPath}`, `/:lng/${page.linkPath}`]}
                    exact={true}
                    component={() => <Recipe pageData={page}/>}
                  />
                );
              })
            }
            <Route
              key={"home"}
              path={["/", "/home", "/:lng/", "/:lng/home"]}
              exact={true}
              component={Home}
            />
          </Switch>
          <Footer />
        </ThemeProvider>
    </Suspense>
  );
};

export default Router;
